const salaries = [
  {
    amount: 1500,
    value: 0,
    label: 'Até R$ 1.500,00',
  },
  {
    amount: 3000,
    value: 3000,
    label: 'De R$ 1.500,01 a R$ 3.000,00',
  },
  {
    amount: 5000,
    value: 6000,
    label: 'De R$ 3.000,01 a R$ 5.000,00',
  },
  {
    amount: 7000,
    value: 9000,
    label: 'De R$ 5.000,01 a R$ 7.000,00',
  },
  {
    amount: 10000,
    value: 12000,
    label: 'De R$ 7.000,01 a R$ 10.000,00',
  },
  {
    amount: 15000,
    value: 15000,
    label: 'De R$ 10.000,01 a R$ 15.000,00',
  },
  {
    amount: 20000,
    value: 18000,
    label: 'Acima de R$ 15.000,01',
  },
]

export { salaries }
