import React from 'react'

import { useNotify, Utils } from '@azos/shared'
import { SimulationLoading, Wizard, WizardItem } from '@presentation/components'
import { useSimulation } from '@presentation/providers/SimulationProvider'
import Router, { useRouter } from 'next/router'

import { Wrapper } from './containers'
import {
  INITIAL_VALUES,
  SimulationData,
  SimulationProps,
  SimulationValue,
} from './Simulation.props'
import {
  Birthdate,
  birthdateValidation,
  Gender,
  genderValidation,
  IMC,
  imcValidation,
  Profession,
  professionValidation,
  Salary,
  salaryValidation,
  Smoker,
  smokerValidation,
} from './steps'

const Simulation = ({
  analytics,
  quotationData,
  widgetQuotationData,
  initialStep,
  createQuotationService,
}: SimulationProps) => {
  const { setSection } = useSimulation()

  const { addNotify } = useNotify()
  const { query } = useRouter()

  const [isLoading, setLoading] = React.useState<boolean>(false)

  const handleSimulationAnswerSubmit = React.useCallback(
    async (value: { [key: string]: any }) => {
      analytics.simulation.answer.execute(
        Object.entries(value).map(([key, value]: [string, any]) => {
          return { questionId: key, answerValue: value }
        }),
      )
    },
    [analytics],
  )

  React.useEffect(() => {
    setSection(1)
    analytics.simulation.start.execute()
  }, [analytics, setSection])

  React.useEffect(() => {
    if (query.error) {
      addNotify({
        message: query.error as string,
        status: 'error',
      })
    }
  }, [addNotify, query.error])

  React.useEffect(() => {
    if (widgetQuotationData) {
      const {
        birth_date,
        gender,
        height,
        weight,
        is_smoker,
        price,
        widgetOrigin,
      } = widgetQuotationData

      const widgetInputEvents = [
        {
          birth_date: Utils.date.formatDate(birth_date),
        },
        {
          gender: gender,
        },
        {
          height: +height,
          weight: +weight,
        },
        {
          is_smoker: is_smoker,
        },
      ]

      widgetInputEvents.forEach(widgetInput =>
        handleSimulationAnswerSubmit(widgetInput),
      )

      analytics.widget.homeFastQuotation.execute({
        birthDate: Utils.date.formatDate(birth_date),
        isSmoker: is_smoker,
        height: height.toString(),
        weight: weight.toString(),
        gender,
        price,
        widgetOrigin,
      })
    }
  }, [
    widgetQuotationData,
    handleSimulationAnswerSubmit,
    analytics.widget.homeFastQuotation,
  ])

  const initialValues = React.useMemo<SimulationValue>(() => {
    if (!!widgetQuotationData) {
      return {
        birth_date: new Date(widgetQuotationData.birth_date),
        gender: widgetQuotationData.gender,
        height: widgetQuotationData.height,
        weight: widgetQuotationData.weight,
        is_smoker: widgetQuotationData.is_smoker,
        profession: {},
        salary: null,
      }
    }

    if (!!quotationData) {
      return {
        birth_date: new Date(quotationData.birthDate),
        gender: quotationData.gender,
        height: quotationData.height,
        weight: quotationData.weight,
        is_smoker: quotationData.isSmoker,
        profession: {
          id: quotationData.professionId,
          description: quotationData.professionDescription,
        },
        salary: quotationData.salary ?? null,
      }
    }

    return INITIAL_VALUES
  }, [quotationData, widgetQuotationData])

  const handleSimulationSubmit = React.useCallback(
    async (data: SimulationData) => {
      try {
        setLoading(true)

        const {
          birth_date,
          gender,
          height,
          weight,
          is_smoker,
          profession,
          salary,
        } = data

        const quotation = await createQuotationService.execute({
          birthDate: birth_date,
          gender,
          height: +height,
          weight: +weight,
          isSmoker: is_smoker,
          professionId: profession.id,
          professionDescription: profession.description,
          salary,
        })

        analytics.simulation.income.execute({
          quotationId: quotation.quotationId,
          proposalId: quotation.proposalId,
          salary,
        })

        await Router.push('/simulacao/calculadora')
      } catch (error) {
        setLoading(false)

        if (error instanceof Error) {
          addNotify({
            message: error.message,
            status: 'error',
          })
        }
      }
    },
    [
      addNotify,
      analytics.simulation.income,
      createQuotationService,
      setLoading,
    ],
  )

  return (
    <>
      {isLoading && <SimulationLoading />}

      <Wizard
        config={{
          initialStep,
          initialValues,
          onSubmit: handleSimulationSubmit,
        }}
        wrapper={Wrapper}
      >
        <WizardItem
          component={Birthdate}
          config={{
            schema: birthdateValidation,
            onSubmit: async (value: SimulationData) => {
              handleSimulationAnswerSubmit({
                birth_date: Utils.date.formatDate(value.birth_date),
              })
            },
          }}
        />
        <WizardItem
          component={Gender}
          config={{
            schema: genderValidation,
            hideNextButton: true,
            onSubmit: async (value: SimulationData) => {
              handleSimulationAnswerSubmit({
                gender: value.gender,
              })
            },
          }}
        />
        <WizardItem
          component={IMC}
          config={{
            schema: imcValidation,
            onSubmit: async (value: SimulationData) => {
              handleSimulationAnswerSubmit({
                height: value.height,
                weight: value.weight,
              })
            },
          }}
        />
        <WizardItem
          component={Smoker}
          config={{
            schema: smokerValidation,
            hideNextButton: true,
            onSubmit: async (value: SimulationData) => {
              handleSimulationAnswerSubmit({
                is_smoker: value.is_smoker,
              })
            },
          }}
        />
        <WizardItem
          component={Profession}
          config={{
            schema: professionValidation,
            onSubmit: async (value: SimulationData) => {
              handleSimulationAnswerSubmit({
                profession: value.profession.name,
              })
            },
          }}
        />
        <WizardItem
          component={Salary}
          config={{
            schema: salaryValidation,
            hideNextButton: true,
            onSubmit: async (value: SimulationData) => {
              handleSimulationAnswerSubmit({
                salary: value.salary,
              })
            },
          }}
        />
      </Wizard>
    </>
  )
}

export default Simulation
