import React from 'react'

import { Button } from '@azos/shared'
import Box from '@mui/material/Box'
import { useWizard } from '@presentation/components'

const Step: React.FCC = () => {
  const { nextStep } = useWizard()

  return (
    <Box>
      <Button onClick={nextStep}>Next</Button>
    </Box>
  )
}

export default Step
