import React from 'react'

import { InputBirthdate } from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import { Emoticon, Subtitle } from '@presentation/components'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { BirthdateProps } from './Birthdate.props'

const ACTIONS = {
  BIRTH_DATE_INPUT: B2CUtils.actions.simulation.INPUT_BIRTHDATE,
}

const Birthdate = ({ context }: BirthdateProps) => {
  const { isTransition } = context

  const inputRef = React.useRef<HTMLInputElement>(null)

  const { values, errors, touched, setFieldValue, handleBlur, isSubmitting } =
    useFormikContext<SimulationValue>()

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isTransition,
    [isSubmitting, isTransition],
  )

  return (
    <Container>
      <Subtitle>
        <Emoticon>👶</Emoticon>
        Quando você nasceu?
      </Subtitle>

      <InputBirthdate
        inputRef={inputRef}
        name="birth_date"
        value={values.birth_date}
        onBlur={handleBlur}
        onChange={date => setFieldValue('birth_date', date, true)}
        inputProps={{
          error: touched.birth_date && !!errors.birth_date,
          helperText: touched.birth_date && errors.birth_date,
        }}
        disabled={isDisabled}
        parentName={ACTIONS.BIRTH_DATE_INPUT}
      />
    </Container>
  )
}

export default Birthdate
