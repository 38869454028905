import React from 'react'

import { useRouter } from 'next/router'

export const ScrollToTop: React.FCC = () => {
  const location = useRouter()

  React.useEffect(() => {
    window?.scrollTo(0, 0)
  }, [location])

  return null
}
