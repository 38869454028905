import React from 'react'

import {
  Coverage,
  Link,
  RadioButton,
  RadioButtonData,
  RadioButtonOptions,
  Text,
} from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import { salaries } from '@main/config/averages'
import Box from '@mui/material/Box'
import { Subtitle } from '@presentation/components'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { SalaryProps } from './Salary.props'

const ACTIONS = {
  BUTTON_SALARY: B2CUtils.actions.simulation.BUTTON_SALARY,
  MODAL_OPEN_SALARY: B2CUtils.actions.simulation.MODAL_OPEN_SALARY,
}

const Salary = ({ context }: SalaryProps) => {
  const { isTransition } = context

  const { values, errors, setFieldValue, submitForm, isSubmitting } =
    useFormikContext<SimulationValue>()

  const [openInfoDetails, setOpenInfoDetails] = React.useState(false)

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isTransition,
    [isSubmitting, isTransition],
  )

  const options = React.useMemo<RadioButtonOptions[]>(
    () =>
      salaries
        .sort((a, b) => (a.amount <= b.amount ? 1 : -1))
        .map(item => ({
          value: item.amount,
          label: item.label,
        })),
    [],
  )

  const handleSelect = React.useCallback(
    async (value: RadioButtonData) => {
      const salaryOption = salaries.find(item => item.amount === value)
      const salaryValue = salaryOption?.amount
      setFieldValue('salary', salaryValue, true)

      submitForm()
    },
    [setFieldValue, submitForm],
  )

  return (
    <Container controlProps={{ hideNextButton: true }}>
      <Subtitle>E qual é a sua renda mensal individual?</Subtitle>
      <Link
        href="#"
        color="primary"
        onClick={() => setOpenInfoDetails(true)}
        underline="hover"
        parentName={ACTIONS.MODAL_OPEN_SALARY}
      >
        Veja como preencher a sua renda mensal individual corretamente.
      </Link>

      <RadioButton
        name="salary"
        columns="flex"
        options={options}
        value={values.salary}
        onChange={handleSelect}
        error={!!errors.salary}
        helperText={errors.salary}
        disabled={isDisabled}
        parentName={ACTIONS.BUTTON_SALARY}
      />

      <Coverage
        open={openInfoDetails}
        onClose={() => setOpenInfoDetails(false)}
      >
        <Box>
          <Subtitle>Algumas dicas importantes: </Subtitle>
          <ul>
            <Text component="li">
              Informe apenas a renda mensal individual, não a da família;
            </Text>
            <Text component="li">
              Se você for autônomo, empresário ou dono de empresa: considere o
              pró-labore/renda líquida, não o faturamento da empresa;
            </Text>
            <Text component="li">
              Em caso de renda Renda variável: informe a média dos últimos 6
              meses.
            </Text>
          </ul>
        </Box>
      </Coverage>
    </Container>
  )
}

export default Salary
