import React from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Control, ControlProps } from '@presentation/components/Wizard'
import { useWizard } from '@presentation/components/Wizard/providers'

import { Root } from './Container.styles'

type ContainerProps = {
  controlProps?: Partial<ControlProps>
  scrollable?: boolean
}

const Container: React.FCC<ContainerProps> = ({
  children,
  controlProps,
  scrollable = false,
}) => {
  const { prevStep } = useWizard()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isScrollable = React.useMemo<boolean>(
    () => scrollable && isMobile,
    [isMobile, scrollable],
  )

  return (
    <Root $scrollable={isScrollable}>
      <div>
        <div className="content">{children}</div>
        {isScrollable && (
          <Control {...controlProps} className="control" onPrev={prevStep} />
        )}
      </div>
    </Root>
  )
}

export default Container
