import React from 'react'

import { ButtonSelect, Coverage } from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import Box, { BoxProps } from '@mui/material/Box'
import { Section, useWizard } from '@presentation/components'

const ACTIONS = {
  VIEWER_MOBILE_STEP: B2CUtils.actions.general.VIEWER_MOBILE_STEP,
}

const Header: React.VFC<BoxProps> = props => {
  const { step, max } = useWizard()

  const [openSummaryDetails, setOpenSummaryDetails] =
    React.useState<boolean>(false)

  const stepText = React.useMemo(() => step + 1, [step])

  return (
    <Box {...props}>
      <ButtonSelect
        title={`Passo ${stepText} de ${max}`}
        description="Dados pessoais"
        onClick={() => setOpenSummaryDetails(true)}
        endIcon={null}
        sx={{ width: 1 }}
        parentName={ACTIONS.VIEWER_MOBILE_STEP}
      />

      <Coverage
        dark
        open={openSummaryDetails}
        onClose={() => setOpenSummaryDetails(false)}
      >
        <Section section={1} />
      </Coverage>
    </Box>
  )
}

export default Header
