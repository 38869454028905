import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  profession: Yup.object().shape({
    id: Yup.string().required('Você deve informar a sua profissão'),
    description: Yup.string().when('requiresDescription', {
      is: (field: boolean) => field,
      then: Yup.string().required('Especifique a profissão'),
    }),
  }),
})
