import { Analytics } from '@azos/analytics'
import { Gender, WidgetQuotation } from '@azos/core'
import { ICreateQuotationService } from '@data/services'
import { LoadQuotationByIdUseCase } from '@domain/usecases'

export type SimulationPageProps = {
  widgetQuotationData?: WidgetQuotation
  quotationData?: LoadQuotationByIdUseCase.Response
  initialStep?: number
}

export type SimulationProps = SimulationPageProps & {
  createQuotationService: ICreateQuotationService
  analytics: Analytics
}

export type SimulationValue = {
  has_insurance?: boolean
  birth_date: Date | null
  gender: Gender | null
  height: string | number | undefined
  weight: string | number | undefined
  is_smoker: boolean | null | undefined
  profession: Partial<{
    id: string
    description: string
    requiresDescription: boolean
    name: string
  }>
  salary: number | null
}

export type SimulationData = {
  has_insurance?: boolean
  birth_date: Date
  gender: Gender
  height: string | number
  weight: string | number
  is_smoker: boolean
  profession: {
    id: string
    description: string
    requiresDescription: boolean
    name: string
  }
  salary: number
}

export const INITIAL_VALUES: SimulationValue = {
  birth_date: null,
  gender: null,
  height: '',
  weight: '',
  is_smoker: null,
  profession: {},
  salary: null,
}

// export const INITIAL_VALUES: SimulationValue = {
//   birth_date: new Date(2000, 0, 1),
//   gender: Gender.MALE,
//   height: '1.80',
//   weight: '80',
//   is_smoker: true,
//   profession: {},
//   salary: null,
// }
