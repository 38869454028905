import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  height: Yup.number()
    .min(1, 'Você deve ter ao menos 1m')
    .max(2.5, 'Você não pode ter mais de 2,50m')
    .required('Sua altura é utilizada para calcular seu seguro'),
  weight: Yup.number()
    .min(20, 'Verifique o seu peso')
    .max(300, 'Você não pode ter mais de 300kg')
    .required('Seu peso é utilizado para calcular seu seguro'),
})
