import React from 'react'

import { Form } from '@presentation/components'
import { ScrollToTop } from '@presentation/hooks/useScrollToTop'

import { Header } from '../../components'
import { WrapperProps } from './Wrapper.props'
import { Root } from './Wrapper.styles'

const Wrapper: React.FCC<WrapperProps> = ({ config, children }) => {
  return (
    <Root>
      <ScrollToTop />
      <Header className="wrapper--header" />
      <Form initialValues={config.initialValues} onSubmit={config.onSubmit}>
        {children}
      </Form>
    </Root>
  )
}

export default Wrapper
