import React from 'react'

import {
  Coverage,
  Input,
  InputProfession,
  Link,
  useNotify,
  Text,
} from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import { Profession as ProfessionModel } from '@domain/models'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import { Subtitle } from '@presentation/components'
import { useProfessions } from '@presentation/hooks/useProfessions'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { ProfessionProps } from './Profession.props'

const ACTIONS = {
  INPUT_PROFESSION_AUTOCOMPLETE:
    B2CUtils.actions.simulation.INPUT_PROFESSION_AUTOCOMPLETE,
  MODAL_OPEN_PROFESSION: B2CUtils.actions.simulation.MODAL_OPEN_PROFESSION,
}

const Profession = ({ context }: ProfessionProps) => {
  const { isTransition } = context

  const { addNotify } = useNotify()

  const {
    values,
    errors,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleBlur,
  } = useFormikContext<SimulationValue>()

  const { data: professions, isLoading, isError } = useProfessions()

  const [professionValue, setProfessionValue] = React.useState<string>('')
  const [openInfoDetails, setOpenInfoDetails] = React.useState(false)

  const professionsOptions = React.useMemo<string[]>(
    () =>
      professions
        ?.filter(item => !item.requiresDescription)
        .map(item => item.name) || [],
    [professions],
  )

  const isDisabled = React.useMemo<boolean>(
    () => isLoading || isSubmitting || isTransition,
    [isLoading, isSubmitting, isTransition],
  )

  const noOptionText = React.useMemo<string>(
    () =>
      professions?.find(item => item.requiresDescription)?.name ||
      'Não encontrado',
    [professions],
  )

  const requiredDescription = React.useMemo<boolean>(
    () =>
      professions?.find(item => item.professionId === values.profession?.id)
        ?.requiresDescription || false,
    [professions, values.profession?.id],
  )
  const selectProfession = React.useCallback(
    (profession: ProfessionModel, description = '') => {
      const { professionId, name, requiresDescription } = profession
      setProfessionValue(name)
      setFieldValue(
        'profession',
        { id: professionId, description, requiresDescription, name },
        !requiresDescription,
      )
    },
    [setFieldValue],
  )

  const handleProfessionChange = React.useCallback(
    (_event: React.ChangeEvent<any>, data = '') => {
      setProfessionValue(data)

      const profession = professions?.find(item => item.name === data)

      if (profession) {
        selectProfession(profession)
      }
    },
    [professions, selectProfession],
  )

  React.useEffect(() => {
    if (
      !professionValue &&
      !!values.profession?.id &&
      !!professions &&
      professions?.length > 0
    ) {
      const profession = professions?.find(
        item => item.professionId === values.profession?.id,
      )

      if (profession) {
        selectProfession(profession, values.profession?.description)
      }
    }
  }, [
    professionValue,
    professions,
    selectProfession,
    values.profession?.description,
    values.profession?.id,
  ])

  React.useEffect(() => {
    if (!!professionValue && !requiredDescription) {
      setFieldValue('profession.description', '')
    }
  }, [professionValue, requiredDescription, setFieldValue])

  React.useEffect(() => {
    if (isError) {
      addNotify({
        message: 'Houve um erro ao carregar as profisssões',
        status: 'error',
      })
    }
  }, [addNotify, isError])

  return (
    <Container>
      <Subtitle>Conta pra gente: qual é a sua profissão atual?</Subtitle>
      <Link
        href="#"
        color="primary"
        onClick={() => setOpenInfoDetails(true)}
        underline="hover"
        parentName={ACTIONS.MODAL_OPEN_PROFESSION}
      >
        Considere a profissão que você se dedica no presente momento. Ver mais
        dicas
      </Link>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <InputProfession
            parentName={ACTIONS.INPUT_PROFESSION_AUTOCOMPLETE}
            loading={isLoading}
            options={professionsOptions}
            value={professionValue}
            onChange={handleProfessionChange}
            noOptionText={noOptionText}
            error={!!errors.profession?.id}
            helperText={errors.profession?.id}
            disabled={isDisabled}
            fullWidth
          />
        </Grid>

        <Fade in={requiredDescription} unmountOnExit>
          <Grid item xs={12}>
            <Input
              autoFocus
              name="profession.description"
              label="Especifique"
              value={values.profession?.description || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isDisabled}
              error={!!errors.profession?.description}
              helperText={errors.profession?.description}
              fullWidth
            />
          </Grid>
        </Fade>
      </Grid>

      <Coverage
        open={openInfoDetails}
        onClose={() => setOpenInfoDetails(false)}
      >
        <Box>
          <Subtitle>Algumas dicas importantes: </Subtitle>
          <ul>
            <Text component="li">
              Informe a profissão que exerce, mesmo se formada(o) em outra área;
            </Text>
            <Text component="li">
              Se tem mais de uma, informe a que dedica mais tempo;
            </Text>
            <Text component="li">
              Se não achar na lista, escolha &quot;Outra&quot; e especifique no
              campo de preenchimento;
            </Text>
            <Text component="li">
              Autônomo ou MEI: escolha a profissão mais relacionada à sua
              atividade diária.
            </Text>
          </ul>
        </Box>
      </Coverage>
    </Container>
  )
}

export default Profession
