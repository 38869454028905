import React from 'react'

import analytics from '@azos/analytics'
import SimulationPage, {
  SimulationPageProps,
} from '@presentation/pages/simulation'
import Head from 'next/head'

import { makeCreateQuotationService } from '../services'

const Render = (props: SimulationPageProps) => (
  <>
    <Head>
      <title>Simule o seu seguro | Azos - Um seguro para a sua vida</title>
    </Head>
    <SimulationPage
      {...props}
      createQuotationService={makeCreateQuotationService()}
      analytics={analytics}
    />
  </>
)

export default Render
