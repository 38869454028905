import dayjs from 'dayjs'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  birth_date: Yup.date()
    .max(
      dayjs().subtract(18, 'y').toDate(),
      'Atualmente asseguramos pessoas maiores que 18 anos',
    )
    .min(
      dayjs().subtract(66, 'y').toDate(),
      'Atualmente asseguramos pessoas menores que 66 anos',
    )
    .required('Sua data de nascimento é utilizada para calcular seu seguro')
    .nullable()
    .typeError('Insira uma data válida'),
})
