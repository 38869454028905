import React from 'react'

import {
  Coverage,
  Link,
  RadioButton,
  RadioButtonData,
  Text,
} from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import Box from '@mui/material/Box'
import { Subtitle } from '@presentation/components'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { SmokerProps } from './Smoker.props'

const ACTIONS = {
  BUTTON_SMOKER: B2CUtils.actions.simulation.BUTTON_SMOKER,
  MODAL_OPEN_SMOKER: B2CUtils.actions.simulation.MODAL_OPEN_SMOKER,
}

const OPTIONS = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

const Smoker = ({ context }: SmokerProps) => {
  const { isTransition } = context

  const { values, errors, setFieldValue, submitForm, isValid, isSubmitting } =
    useFormikContext<SimulationValue>()
  const [openInfoDetails, setOpenInfoDetails] = React.useState<boolean>(false)

  const [selected, setSelected] = React.useState<RadioButtonData | null>(null)

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isTransition,
    [isSubmitting, isTransition],
  )

  const handleChange = React.useCallback(
    (value: RadioButtonData) => {
      setSelected(value)
      setFieldValue('is_smoker', value, true)
    },
    [setFieldValue],
  )

  React.useEffect(() => {
    if (selected !== null && isValid) {
      submitForm()
    }
  }, [isValid, selected, submitForm])

  return (
    <>
      <Container>
        <Subtitle>
          Também precisamos saber se você fumou produtos com nicotina ou
          cigarros eletrônicos nos últimos 24 meses.
        </Subtitle>

        <Link
          href="#"
          color="primary"
          onClick={() => setOpenInfoDetails(true)}
          underline="hover"
          parentName={ACTIONS.MODAL_OPEN_SMOKER}
        >
          O que isso significa?
        </Link>

        <RadioButton
          name="is_smoker"
          columns={2}
          options={OPTIONS}
          value={values.is_smoker}
          onChange={handleChange}
          error={!!errors.is_smoker}
          helperText={errors.is_smoker}
          disabled={isDisabled}
          parentName={ACTIONS.BUTTON_SMOKER}
        />
      </Container>

      <Coverage
        open={openInfoDetails}
        onClose={() => setOpenInfoDetails(false)}
      >
        <Box>
          <Subtitle>O que isso significa?</Subtitle>
          <Text variant="body1" sx={{ mt: '1.5rem' }}>
            Informe se você consumiu ou consome regularmente produtos que
            contenham tabaco e/ou nicotina, ou outras substâncias danosas ao
            sistema respiratório, como por exemplo: cigarros eletrônicos
            (vapes), cachimbos, fumo de palha, dentre outros.
          </Text>
        </Box>
      </Coverage>
    </>
  )
}

export default Smoker
