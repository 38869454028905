import { styled, css } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
  position: relative;
  height: 100%;

  & .wrapper--header {
    background-color: ${theme.palette.background.default};
    padding: ${theme.spacing(4, 4, 2, 4)};

    @media (min-width: ${theme.breakpoints.values.md}px) {
      display: none;
    }

    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
  },

  & > form {
    min-height: calc(100% - 110px);
    display: flex;
    flex-direction: column;


    @media (min-width: ${theme.breakpoints.values.md}px) {
      min-height: 100%;
    }

    & > div:first-of-type {
      flex: 1;
      display: flex;
      flex-direction: column;
    },
  },
`,
)
