import React from 'react'

import { InputHeight, InputWeight } from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import { Subtitle } from '@presentation/components'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { BirthdateProps } from './IMC.props'

const ACTIONS = {
  B2C_TYPE: B2CUtils.actions.simulation.B2C_TYPE,
}

const IMC: React.FCC<BirthdateProps> = ({ context }) => {
  const { isTransition } = context

  const inputRef = React.useRef<HTMLInputElement>(null)

  const { values, errors, touched, handleBlur, handleChange, isSubmitting } =
    useFormikContext<SimulationValue>()

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isTransition,
    [isSubmitting, isTransition],
  )

  return (
    <Container>
      <Subtitle>
        Legal! Agora, quanto você tem de altura e qual é o seu peso.
      </Subtitle>

      <InputHeight
        inputRef={inputRef}
        name="height"
        value={values.height}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.height && !!errors.height}
        helperText={touched.height && errors.height}
        disabled={isDisabled}
        fullWidth
        parentName={ACTIONS.B2C_TYPE}
      />

      <InputWeight
        name="weight"
        value={values.weight}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched.weight && !!errors.weight}
        helperText={touched.weight && errors.weight}
        disabled={isDisabled}
        fullWidth
        parentName={ACTIONS.B2C_TYPE}
      />
    </Container>
  )
}

export default IMC
