import {
  SRWServiceResponse,
  SWRService,
  SWRServices,
  useFetch,
} from '@azos/core'
import { Profession } from 'domain/models'
import { makeLoadProfessionsService } from 'main/factories/services'

type ProfessionsResponse = Profession[]
type ProfessionsResult = SRWServiceResponse<ProfessionsResponse>

export const useProfessions = (
  service: SWRServices = makeLoadProfessionsService(),
): ProfessionsResult => {
  const { data, error } = useFetch<ProfessionsResponse>(
    new SWRService(service.getUrl(), () => service.execute()),
    {
      revalidateOnFocus: false,
    },
  )

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  }
}
