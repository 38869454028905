import { styled, css } from '@azos/shared'
import Box, { BoxProps } from '@mui/material/Box'

type RootProps = BoxProps & {
  $scrollable: boolean
}

export const Root = styled(Box)<RootProps>(
  ({ theme, $scrollable }) => css`
    position: relative;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow-y: auto;
    flex: 1;
    max-height: 100%;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      padding: ${theme.spacing(4)};

      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(5)};
      }
      ${theme.breakpoints.up('md')} {
        max-width: 80%;
      }

      > div.content {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > *:not(.subtitle) {
          margin-top: ${theme.spacing(5)};
        }
      }

      > div.control {
        margin-top: ${theme.spacing(4)};
      }
    }
  `,
)
