import React from 'react'

import { Gender } from '@azos/core'
import {
  Coverage,
  Link,
  RadioButton,
  RadioButtonData,
  Text,
} from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import Box from '@mui/material/Box'
import { Subtitle } from '@presentation/components'
import { useFormikContext } from 'formik'

import { Container } from '../../containers'
import { SimulationValue } from '../../Simulation.props'
import { GenderProps } from './Gender.props'

const ACTIONS = {
  BUTTON_GENDER: B2CUtils.actions.simulation.BUTTON_GENDER,
  MODAL_OPEN_GENDER: B2CUtils.actions.simulation.MODAL_OPEN_GENDER,
}

const OPTIONS = [
  { value: Gender.MALE, label: 'Masculino' },
  { value: Gender.FEMALE, label: 'Feminino' },
]

const Birthdate = ({ context }: GenderProps) => {
  const { isTransition } = context

  const { values, errors, setFieldValue, submitForm, isValid, isSubmitting } =
    useFormikContext<SimulationValue>()

  const [selected, setSelected] = React.useState<RadioButtonData>()
  const [openGenderDetails, setOpenGenderDetails] =
    React.useState<boolean>(false)

  const isDisabled = React.useMemo<boolean>(
    () => isSubmitting || isTransition,
    [isSubmitting, isTransition],
  )

  const handleChange = React.useCallback(
    (value: RadioButtonData) => {
      setSelected(value)
      setFieldValue('gender', value, true)
    },
    [setFieldValue],
  )

  React.useEffect(() => {
    if (!!selected && isValid) {
      submitForm()
    }
  }, [isValid, selected, submitForm])

  return (
    <>
      <Container>
        <Subtitle>Qual sexo consta no seu registro de nascimento?</Subtitle>

        <Link
          href="#"
          color="primary"
          onClick={() => setOpenGenderDetails(true)}
          parentName={ACTIONS.MODAL_OPEN_GENDER}
        >
          Por que apenas essas duas opções?
        </Link>

        <RadioButton
          name="gender"
          columns={2}
          options={OPTIONS}
          value={values.gender}
          onChange={handleChange}
          error={!!errors.gender}
          helperText={errors.gender}
          disabled={isDisabled}
          parentName={ACTIONS.BUTTON_GENDER}
        />
      </Container>

      <Coverage
        open={openGenderDetails}
        onClose={() => setOpenGenderDetails(false)}
      >
        <Box>
          <Subtitle>
            Por que apenas essas <br /> duas opções?
          </Subtitle>
          <Text variant="body1" sx={{ mt: '1.5rem' }}>
            Sabemos que existem mais identidades de gênero do que apenas homem e
            mulher. No entanto, o mercado de seguros está aprendendo sobre o
            impacto de outras identidades de gênero no Seguro de Vida. Ao
            selecionar o sexo de acordo com seu registro de nascimento,
            conseguiremos uma cotação mais precisa.
          </Text>
        </Box>
      </Coverage>
    </>
  )
}

export default Birthdate
